@mixin generate-theme-vars($theme) {
  $primary-lighter: mat-color(map-get($theme, primary), lighter);
  $primary: mat-color(map-get($theme, primary), default);
  $primary-darker: mat-color(map-get($theme, primary), darker);
  $accent-lighter: mat-color(map-get($theme, accent), lighter);
  $accent: mat-color(map-get($theme, accent), default);
  $accent-darker: mat-color(map-get($theme, accent), darker);

  --primary-color-lighter: #{$primary-lighter};
  --primary-color: #{$primary};
  --primary-color-darker: #{$primary-darker};
  --accent-color-lighter: #{$accent-lighter};
  --accent-color: #{$accent};
  --accent-color-darker: #{$accent-darker};
}
