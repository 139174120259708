@import "assets/scss/functions";
@import "~@angular/material/theming";

.envision-theme {
  @import "themes/envision-theme";
  @include angular-material-theme($envision-theme);
  @include generate-theme-vars($envision-theme);
}

.jaguar-theme {
  @import "themes/jaguar-theme";
  @include angular-material-theme($jaguar-theme);
  @include generate-theme-vars($jaguar-theme);
}

.mat-form-field-infix {
  border-top: 0 !important;
}

.mat-form-field-wrapper {
  padding: 0 !important;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  display: none !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-color) !important;
}

.mat-checkbox label.mat-checkbox-layout {
  margin: 0 !important;
}

.mat-radio-group > .mat-radio-button {
  color: black;
  font-weight: bold;
}

.mat-radio-button .mat-radio-label {
  color: black;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: white;
  // background-color: var(--primary-color-lighter) !important; /*inner circle color change*/
}

.mat-radio-outer-circle {
  border-color: black !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  background-color: white;
  // border-color: black !important; /*outer ring color change*/
  // border-color: var(--primary-color) !important; /*outer ring color change*/
}

.mat-tab-body-wrapper {
  margin-top: 15px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.jaguarFont {
  font-family: JaguarModern !important;
}

html,
body {
  font-family: "Segoe UI";
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
}

table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

table thead th {
  background-color: var(--primary-color);
  color: white;
  position: sticky;
  top: 0;
  z-index: 400;
  line-height: 28px;
}

table tr {
  border-bottom: thin solid black;
  color: black;
}

.mat-row:nth-child(even) {
  background-color: #c7c7c7;
}

.mat-row:nth-child(odd) {
  background-color: #f3f3f3;
}

table tr:nth-of-type(even) {
  background-color: #c7c7c7;
}

table tr:nth-of-type(odd) {
  background-color: #f3f3f3;
}

.mat-raised-button:hover {
  opacity: 0.8;
}

// .mat-raised-button:disabled {
//   // background-color: var(--primary-color) !important;
//   color: white !important;
//   opacity: 0.8;
// }

.mat-snack-bar-container {
  margin-bottom: 75px !important;
}

.mat-tooltip {
  font-size: 1rem !important;
}

#outputPanel .mat-form-field-infix {
  width: 300px !important;
}
